<template>
    <div class="history_box">
        <div class="info">
            <label>报备名称</label>
            <p>{{ data.reportName }}</p>
        </div>
        <div class="info">
            <label>客户</label>
            <p>{{ data.companyName }}</p>
        </div>
        <div class="step_box">
            <div class="step" v-for="item in listData" :key="item.id">
                <p class="step_title">{{ item.createTime }}</p>
                <p class="step_con">
                    {{ item.content }}
                </p>
            </div>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: #2370eb;
                    border-color: #d5d6df;
                    color: #fff;
                    margin-right: 20px;
                "
                @click="onClose"
                >关 闭</el-button
            >
        </div>
    </div>
</template>

<script>
import { reportHistory } from '@/api/report/report';
export default {
    data() {
        return {
            data: {},
            listData: [],
        };
    },
    methods: {
        getData(row) {
            this.data = row;
            let data = {
                param: {
                    nicheId: row.id,
                },
            };
            reportHistory(data).then((res) => {
                this.listData = res.data.list;
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.history_box {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0 50px 0;
    font-size: 14px;
    .info {
        display: flex;
        width: 80%;
        margin: 0 auto;
        color: #333;
        line-height: 32px;
        label {
            width: 90px;
            font-weight: 600;
        }
        p {
            flex: 1;
        }
    }
    .step_box {
        margin-left: 50px;
        margin-right: 25px;
        margin-top: 20px;
        .step {
            position: relative;
            font-size: 14px;
            .step_title {
                color: #999;
            }
            .step_con {
                color: #333;
                padding-top: 8px;
                padding-bottom: 12px;
            }
        }
        .step:before {
            content: '';
            position: absolute;
            left: -20px;
            top: 7px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #2370eb;
        }
        .step:after {
            content: '';
            position: absolute;
            left: -18px;
            top: 8px;
            height: 100%;
            border-left: 1px dashed #2370eb;
        }
        .step:last-child:after {
            content: '';

            border-left: none;
        }
    }
    //     .el-steps {
    //         /deep/ .el-step__icon {
    //             border: none !important;
    //         }
    //         .circle {
    //             width: 5px;
    //             height: 5px;
    //             border-radius: 50%;
    //             background-color: #2370eb;
    //         }
    //     }
    .bottom-but {
        width: 480px;
        line-height: 48px;
        text-align: right;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
