<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column
                label="报备名称"
                min-width="150"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        v-if="btnP.details"
                        @click="onDetails(scope.row)"
                        :style="{
                            color: btnP.details ? '#2370EB' : '#606266',
                        }"
                    >
                        {{ scope.row.reportName || '- -' }}
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column
                label="客户"
                prop="companyName"
                min-width="150"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                label="域名"
                prop="reportDomain"
                show-overflow-tooltip
                min-width="120"
            >
                <template slot-scope="scope">
                    <div>
                        <el-tooltip effect="dark" placement="bottom">
                            <div>
                                {{ returnDomain(scope.row) }}
                            </div>
                            <div slot="content" class="item">
                                <p
                                    v-for="item in scope.row.nicheDomainBOList"
                                    :key="item.id"
                                >
                                    <span
                                        style="
                                            display: inline-block;
                                            width: 150px;
                                            text-align: left;
                                        "
                                    >
                                        {{ item.domain }}
                                    </span>
                                    <span
                                        class="success"
                                        v-if="item.auditStatus == 1"
                                    >
                                        <i class="el-icon-success"></i>
                                        成功
                                    </span>
                                    <span
                                        class="faid"
                                        v-if="item.auditStatus == 2"
                                    >
                                        <i class="el-icon-warning"></i>
                                        失败
                                    </span>
                                    <span
                                        class="wait"
                                        v-if="item.auditStatus == 0"
                                        ><i class="iconfont icon-dengdai"></i>
                                        待处理</span
                                    >
                                </p>
                            </div>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="报备时间"
                show-overflow-tooltip
                min-width="110"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.reportStartTime">{{
                        scope.row.reportStartTime.slice(0, 10)
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column
                label="回收时间"
                show-overflow-tooltip
                min-width="110"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.reportExpireTime">{{
                        scope.row.reportExpireTime.slice(0, 10)
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip min-width="180">
                <template slot-scope="scope">
                    <span>
                        <i
                            class="iconfont icon-ziyuanxhdpi"
                            v-if="btnP.history"
                            @click="onHistory(scope.row)"
                        ></i>

                        {{ $MailStatus.reportStatus(scope.row.reportStatus) }} -
                        {{
                            $MailStatus.trialStatus(scope.row.trialStatus)
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column
                label="试用到期时间"
                show-overflow-tooltip
                min-width="110"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.trialExpireTime">{{
                        scope.row.trialExpireTime.slice(0, 10)
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>

            <el-table-column label="报备库" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span> {{ scope.row.libraryName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                fixed="right"
                show-overflow-tooltip
                width="120"
            >
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="scope.row.reportStatus != 18 && btnP.set"
                        @click="onSetUp(scope.row)"
                        >设置</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            btnP: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data, btnP) {
            this.tableData = data;
            this.btnP = btnP;
        },
        onDetails(row) {
            if (!this.btnP.details) {
                return this.$message.error('暂无权限');
            }
            this.$emit('onDetails', row);
        },
        onHistory(row) {
            this.$emit('onHistory', row);
        },
        onSetUp(row) {
            this.$emit('onSetUp', row);
        },
        // 域名处理
        returnDomain(row) {
            if (row.nicheDomainBOList && row.nicheDomainBOList.length > 0) {
                return row.nicheDomainBOList[0].domain;
            } else {
                return '- -';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
}
.item {
    p + p {
        margin-top: 10px;
    }
    span {
        display: inline-block;
        vertical-align: middle;
        width: 70px;
        font-size: 12px;
        text-align: center;
        height: 25px;
        line-height: 25px;
        border-radius: 3px;
        margin-left: 10px;
    }
    .success {
        border: 1px solid #2370eb;
        i {
            color: #2370eb;
        }
    }
    .warning {
        border: 1px solid #d0021b;
        i {
            color: #d0021b;
        }
    }
    .wait {
        border: 1px solid #f5a623;
        i {
            color: #f5a623;
        }
    }
    .faid {
        border: 1px solid #d0021b;
        i {
            color: #d0021b;
        }
    }
}
/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
/deep/ .el-table th:nth-child(7) .cell {
    padding-left: 20px;
}
/deep/ td:nth-child(7) .cell {
    padding-left: 0;
}
.icon-ziyuanxhdpi {
    color: #2370eb;
    cursor: pointer;
}
</style>
