<template>
    <div class="customer">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>客户筛选</span>
                    <el-input
                        size="small"
                        style="width: 212px"
                        placeholder="请输入报备名称/客户名称/域名"
                        v-model.trim="search.nameVal"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="onSenior()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        style="border-color: #2370eb"
                    ></el-button>
                    <el-select
                        v-model="search.timeType"
                        size="small"
                        style="width: 120px; margin-right: 13px"
                        placeholder="请选择"
                        class="slee"
                    >
                        <el-option label="报备回收时间" :value="1"></el-option>
                        <el-option label="试用到期时间" :value="2"></el-option>
                    </el-select>
                    <el-radio-group v-model="search.btnTime" size="small">
                        <el-radio-button
                            label="今日"
                            @click.native.prevent="clickBtnTime('今日')"
                        ></el-radio-button>
                        <el-radio-button
                            label="近7天"
                            @click.native.prevent="clickBtnTime('近7天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="本月"
                            @click.native.prevent="clickBtnTime('本月')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        size="small"
                        v-model="search.inpTime"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width: 240px; margin-left: 19px"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="twoSearch()"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 20px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
            <div class="search_more">
                <p>
                    <span>部门筛选</span>
                    <el-select
                        v-model="search.dempVal"
                        size="small"
                        style="width: 100px"
                        clearable
                        placeholder="请选择"
                        @change="changeDepartmentId()"
                    >
                        <el-option
                            v-for="item in dempData"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>
                <p>
                    <span>成员</span>
                    <el-select
                        v-model="search.userVal"
                        size="small"
                        style="width: 100px"
                        clearable
                        placeholder="请选择"
                        @change="getTableData(isStsts)"
                    >
                        <el-option
                            v-for="item in userData"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>
                <p>
                    <span>报备库</span>
                    <el-select
                        v-model="search.libraryCategoryVal"
                        size="small"
                        style="width: 100px"
                        clearable
                        placeholder="请选择"
                        @change="getTableData(isStsts)"
                    >
                        <el-option
                            v-for="item in accountList"
                            :key="item.id"
                            :label="item.accountName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>
            </div>
        </div>
        <div class="table-title">
            <div class="status_btn">
                <span
                    @click="getTableData(1)"
                    :class="isStsts == 1 ? 'isStatusColor' : ''"
                    >报备信息</span
                >
                <span
                    @click="getTableData(2)"
                    :class="isStsts == 2 ? 'isStatusColor' : ''"
                >
                    未试用
                </span>
                <span
                    @click="getTableData(3)"
                    :class="isStsts == 3 ? 'isStatusColor' : ''"
                    >试用中</span
                >
                <span
                    @click="getTableData(4)"
                    :class="isStsts == 4 ? 'isStatusColor' : ''"
                    >已成交</span
                >
                <span
                    @click="getTableData(5)"
                    :class="isStsts == 5 ? 'isStatusColor' : ''"
                    >已放弃</span
                >
                <span
                    @click="getTableData(6)"
                    :class="isStsts == 6 ? 'isStatusColor' : ''"
                    >已到期</span
                >
            </div>
            <button class="add" @click="onReporting()" v-show="btnP.add">
                <i class="iconfont icon-faqibaobei"></i> 报备
            </button>
        </div>
        <Table
            ref="table"
            @onDetails="onDetails"
            @onHistory="onHistory"
            @onSetUp="onSetUp"
        ></Table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>

        <!-- 报备信息 -->
        <el-drawer
            :visible.sync="drawerReportDetail"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        font-weight: 550;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    报备信息
                </div>
            </template>
            <Detail @close="handleClose" ref="detail" />
        </el-drawer>
        <!-- 历史 -->
        <el-drawer
            :visible.sync="drawerHistory"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        font-weight: 550;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    操作历史
                </div>
            </template>
            <History @close="handleClose" ref="history" />
        </el-drawer>
        <!-- 设置 -->
        <el-drawer
            :visible.sync="drawerSetup"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        font-weight: 550;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    设置
                </div>
            </template>
            <SetUp @close="handleClose" ref="setup" />
        </el-drawer>
    </div>
</template>
<script>
import {
    reportList,
    reportGet,
    reportUpdate,
    accountList,
} from '@/api/report/report';
import SetUp from './setup/setup.vue';
import History from './history/history.vue';
import Table from './table/table.vue';
import Detail from '../../aliReporting/details/details.vue';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
export default {
    components: {
        History,
        SetUp,
        Table,
        Detail,
    },
    data() {
        return {
            search: {
                nameVal: '',
                timeType: 1,
                btnTime: '',
                inpTime: [],
                dempVal: '',
                userVal: '',
            },
            dempData: [],
            userData: [],
            tableData: [{}],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            isStsts: 0,
            drawerSetup: false,
            drawerReportDetail: false,
            drawerHistory: false,
            direction: 'rtl',
            btnP: {},
            isPage: false,
            pageData: {},
            accountList: [],
            surplus: '',
        };
    },
    mounted() {
        if (
            sessionStorage.getItem('pageData') &&
            JSON.parse(sessionStorage.getItem('pageData'))
        ) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
        }
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            if (btn.depSearch) {
                this.getDempData();
            }
            if (!btn.depSearch && btn.searchUser) {
                this.getUserList(sessionStorage.getItem('departmentId'));
            }
            this.getTableData(1);
            this.getaccountList();
        },
        getaccountList() {
            let data = {
                param: {
                    productType: 2,
                },
                pageNum: 0,
                pageSize: 0,
            };

            accountList(data).then((res) => {
                if (res.code == 200) {
                    this.accountList = res.data.list;
                }
            });
        },
        onSenior() {
            this.search.timeType = 1;
            this.search.btnTime = '';
            this.search.inpTime = [];
            this.search.dempVal = '';
            this.search.userVal = '';

            this.currentPage = 1;
            this.pagesize = 20;
            this.getTableData(1);
        },
        twoSearch() {
            this.search.nameVal = '';
            this.getTableData(this.isStsts);
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
            });
        },

        changeDepartmentId() {
            this.search.adminId = '';
            this.getTableData(this.isStsts);
            this.getUserList(this.search.dempVal);
        },
        getUserList(departmentId) {
            let data = {
                param: { departmentId },
            };

            roleList(data).then((res) => {
                this.userData = res.data;
            });
        },
        getTableData(i) {
            this.isStsts = i;
            let data = {
                param: {
                    productType: 2,

                    name: this.search.nameVal,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (i == 1) {
                data.param.reportStatusList = [3, 17, 18, 19];
            } else if (i == 2) {
                data.param.reportStatusList = [3, 17, 18, 19];
                data.param.trialStatusList = [1];
            } else if (i == 3) {
                data.param.reportStatusList = [3, 17, 18, 19];
                data.param.trialStatusList = [3];
            } else if (i == 4) {
                data.param.reportStatusList = [18];
            } else if (i == 5) {
                data.param.reportStatusList = [17];
            } else if (i == 6) {
                data.param.reportStatusList = [19];
            }
            var startTime = '';
            var endTime = '';
            if (this.search.btnTime != '') {
                let date = new Date();
                this.search.inpTime = [];
                if (this.search.btnTime == '今日') {
                    endTime = this.$searchTime.getNextDate(date, 0);
                    startTime = this.$searchTime.getNextDate(date, 0);
                } else if (this.search.btnTime == '近7天') {
                    startTime = this.$searchTime.getNextDate(date, 0);
                    endTime = this.$searchTime.getNextDate(date, -6);
                } else if (this.search.btnTime == '本月') {
                    startTime = this.$searchTime.DateUtil.getStartDayOfMonth();
                    endTime = this.$searchTime.DateUtil.getEndDayOfMonth();
                }
            }
            if (this.search.inpTime && this.search.inpTime.length > 0) {
                startTime = this.search.inpTime[0];
                endTime = this.search.inpTime[1];
            }
            if (this.search.timeType == 1) {
                data.param.startReportExpireDate = startTime;
                data.param.endReportExpireDate = endTime;
            } else if (this.search.timeType == 2) {
                data.param.startTrialExpireDate = startTime;
                data.param.endTrialExpireDate = endTime;
            }
            if (this.btnP.depSearch && !this.btnP.userSearch) {
                data.param.departmentId = this.search.dempVal;
            }

            if (this.btnP.depSearch && this.btnP.userSearch) {
                data.param.departmentId = this.search.dempVal;
                data.param.adminId = this.search.userVal;
            }
            if (!this.btnP.depSearch && !this.btnP.userSearch) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (this.search.nameVal) {
                data.param.name = this.search.nameVal;
            }
            if (this.search.libraryCategoryVal != '') {
                data.param.libraryCategory = this.search.libraryCategoryVal;
            }
            this.pageData = data;
            reportList(data).then((res) => {
                this.total = res.data.total;
                this.$refs.table.getData(res.data.list, this.btnP);
            });
        },

        // 跟进时间点击
        clickBtnTime(e) {
            this.search.nameVal = '';
            e === this.search.btnTime
                ? (this.search.btnTime = '')
                : (this.search.btnTime = e);
            this.search.inpTime = [];
        },

        // 时间输入框变化
        expireTime() {
            this.search.nameVal = '';
            if (this.search.inpTime && this.search.inpTime.length > 0) {
                this.search.btnTime = '';
            }
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData(this.isStsts);
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData(this.isStsts);
        },

        // 操作历史
        onHistory(row) {
            this.drawerHistory = true;
            setTimeout(() => {
                this.$refs.history.getData(row);
            }, 0);
        },
        // 设置
        onSetUp(row) {
            this.drawerSetup = true;
            setTimeout(() => {
                this.$refs.setup.echoData(row);
            });
        },
        handleClose() {
            this.drawerHistory = false;
            this.drawerSetup = false;
            this.drawerReportDetail = false;
            this.getTableData(this.isStsts);
        },
        onDetails(row) {
            this.drawerReportDetail = true;
            this.reportGet(row.id);
        },
        // 获取单条数据
        reportGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            reportGet(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.detail.getData(res.data);
                }
            });
        },
        handleDetails() {
            this.drawer = false;

            this.$formDetails.commit('emptyData');
            this.$choiceLabel.commit('emptyData');
            this.getTableData(this.isStsts);
        },
        // 重置
        searchEmpty() {
            for (let i in this.search) {
                this.search[i] = '';
            }
            this.search.timeType = 1;
            this.search.inpTime = [];
            this.getTableData(1);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search_more {
            padding-bottom: 10px;
            p {
                display: inline-block;
            }
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .status_btn {
            span {
                display: inline-block;
                border: 1px solid #f0f0f0;
                border-radius: 13px;
                padding: 4px 12px;
                font-size: 13px;
                color: #666666;
                margin-right: 12px;
                cursor: pointer;
            }
            .isStatusColor {
                background: #e9f2ff;
                color: #2370eb;
                border-color: #e9f2ff;
            }
        }

        .add {
            border-color: #2370eb;
            color: #fff;
            padding: 0 15px 0 15px;
            background: #2370eb;
            border: 1px solid #2370eb;
            border-radius: 2px;
            height: 24px;
            .icon-faqibaobei {
                font-size: 12px;
                margin-right: 8px;
            }
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-pagination {
            margin: 5px 0;
        }
    }
}
.content {
    height: 200px;
    p {
        text-align: center;
        margin-top: 50px;
        font-size: 14px;
        color: #333;
        line-height: 30px;
        /deep/ .iconfont {
            color: #f5a623;
            font-size: 50px;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.slee {
    /deep/ .el-input__inner {
        font-weight: 600;
        color: #333;
    }
}
</style>
