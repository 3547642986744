<template>
    <div class="trial">
        <el-form
            ref="form"
            style="flex: 1; margin-top: 20px; margin-left: 40px"
            label-position="left"
            :model="form"
            label-width="100px"
        >
            <el-form-item label="报备名称">
                <span class="brand">{{ data.reportName }}</span>
            </el-form-item>
            <el-form-item label="客户">
                <span class="brand">{{ data.companyName }}</span>
            </el-form-item>
            <el-form-item label="回收时间" v-if="data.reportStatus == 3">
                <el-date-picker
                    v-model="form.reportExpireTime"
                    size="small"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="试用到期时间" v-if="data.trialStatus == 3">
                <el-date-picker
                    v-model="form.trialExpireTime"
                    size="small"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="报备库">
                <el-select
                    v-model="form.libraryCategory"
                    size="small"
                    style="width: 270px"
                    placeholder="请选择"
                    clearable
                    @change="changeLibraryCategory"
                >
                    <el-option
                        v-for="item in accountList"
                        :key="item.id"
                        :label="item.accountName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <p
                    style="color: #999; font-size: 12px"
                    v-if="form.libraryCategory"
                >
                    <i
                        class="el-icon-warning-outline"
                        style="color: #f5a623"
                    ></i>
                    剩余报备名额：{{ surplus }}
                </p>
            </el-form-item>
            <el-form-item label="成交状态" v-if="data.reportStatus == 3">
                <el-checkbox v-model="checked">已成交</el-checkbox>
                <p style="color: #999; font-size: 12px">
                    <i
                        class="el-icon-warning-outline"
                        style="color: #f5a623"
                    ></i>
                    标记为已成交状态后将不可修改
                </p>
            </el-form-item>

            <el-form-item label="备注">
                <el-input
                    type="textarea"
                    v-model="form.settingRemark"
                    size="small"
                    rows="6"
                    placeholder="请输入备注"
                    style="width: 270px"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 270px;
                "
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >保存</el-button
            >
        </div>
    </div>
</template>
<script>
import { reportSetting, accountList } from '@/api/report/report';
export default {
    data() {
        return {
            form: {
                id: '',
                // reportExpireTime: '',
                // libraryCategory: '',
                // trialExpireTime: '',
                // reportStatus: '',
                // settingRemark: '',
            },
            checked: false,
            data: {},
            accountList: [],
            surplus: '',
        };
    },
    methods: {
        echoData(data) {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.checked = false;
            this.data = data;
            this.form.id = data.id;
            this.getaccountList();
        },
        getaccountList() {
            let data = {
                param: {
                    productType: 2,
                },
                pageNum: 0,
                pageSize: 0,
            };

            accountList(data).then((res) => {
                if (res.code == 200) {
                    this.accountList = res.data.list;
                }
            });
        },
        changeLibraryCategory(val) {
            if (val) {
                let obj = this.accountList.find((item) => {
                    return item.id == val;
                });
                this.surplus =
                    Number(obj.maxReportNumber) - Number(obj.consumedNumber);
            }
        },
        onSubmit() {
            let data = {
                param: this.form,
            };
            if (this.checked) {
                data.param.reportStatus = 18;
            }
            if (data.param.libraryCategory && this.surplus <= 0) {
                return this.$message.error(
                    '当前报备库名额不足，请选择其他报备库'
                );
            }
            reportSetting(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('设置成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
            for (let i in this.form) {
                this.form[i] = '';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.trial {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;
        background: #fafafa;

        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-input {
        width: 270px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
</style>
